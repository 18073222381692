import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import logo from "../../../assets/images/logo-sm.png";

const CreateCategoryPage = () => {
  document.title = "Create Category";

  const navigate = useNavigate();
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [title, setTitle] = useState("");
  const [selectFile, setSelectFile] = useState(null);
  const [dataFile, setDataFile] = useState([]);
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadOption, setUploadOption] = useState("single");

  const handleUploadFile = (event) => {
    const files = event.target.files[0];
    setSelectFile(files);
  };

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadOption === "single") {
      if (title !== "") {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("title", title);
          formData.append("categoryImage", selectFile);

          const response = await axios.post(
            `${apiRoute}/api/admin/createCategory`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response, "response");
          if (response.status === 201) {
            setToastMessage(response.message);
            setTimeout(() => {
              navigate("/category-list");
            }, 1500);
          }
        } catch (err) {
          const errSplit = err.split(" ");
          const statusCode = errSplit[errSplit.length - 1];
          if (statusCode === "400") {
            setToastMessage("Image file does not saved,try again");
          } else if (statusCode === "409") {
            setToastMessage("This category title name already exits");
          }
        }
        setLoading(false);
        settoast1(true);
      } else {
        setToastMessage("Please fill all the fields");
      }
    } else {
      if (dataFile.length > 0) {
        try {
          setLoading(true);

          const response = await axios.post(
            `${apiRoute}/api/admin/createMultipleCategory`,
            { dataFile: dataFile }
          );
          if (response.status === 201) {
            setToastMessage(response.message);
            setTimeout(() => {
              navigate("/category-list");
            }, 1500);
          }
        } catch (err) {
          const errSplit = err.split(" ");
          const statusCode = errSplit[errSplit.length - 1];
          if (statusCode === "400") {
            setToastMessage("All Lab Tests, Already in Database");
          }
        }
        setLoading(false);
        settoast1(true);
      }
    }
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const handleUploadExcelFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setDataFile(sheetData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSampleFile = () => {
    const data = [
      { "Cat Name": "EYE" },
      { "Cat Name": "NOSE" },
      { "Cat Name": "EAR" },
      { "Cat Name": "CHEST" },
      { "Cat Name": "ARM" },
    ];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert the JSON data to a worksheet

    // Get the header cell (A1) and apply styling
    worksheet["A1"].s = {
      font: {
        bold: true, // Bold the text
        sz: 16, // Change the font size
      },
    };

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate an Excel file and download it
    XLSX.writeFile(workbook, "sample-data.xlsx"); // This triggers the download
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Category" breadcrumbItem="Create Category" />
          <Row>
            <Col>
              <Card className="px-5">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Select Option :
                      </label>
                      <div className="col-md-10 d-flex align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadios"
                            id="inlineRadios1"
                            defaultValue="option1"
                            defaultChecked={true}
                            onChange={() => {
                              setUploadOption("single");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadios1"
                          >
                            Add Single Category
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadios"
                            id="inlineRadios2"
                            defaultValue="option2"
                            onChange={() => {
                              setUploadOption("multiple");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadios2"
                          >
                            Add Multiple Categories
                          </label>
                        </div>
                      </div>
                    </Row>

                    {uploadOption === "single" ? (
                      <>
                        <Row className="my-4">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Title :
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Title *"
                              required
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </Row>
                        <Row className="mb-4">
                          <label
                            htmlFor="example-password-input"
                            className="col-md-2 col-form-label"
                          >
                            Image :
                          </label>
                          <div className="col-md-10">
                            <input
                              type="file"
                              className="form-control"
                              placeholder="Select Your Image *"
                              onChange={handleUploadFile}
                            />
                          </div>
                        </Row>
                        <Row className="mb-4">
                          <div className="col-10 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary px-5"
                              disabled={loading}
                            >
                              Submit
                            </button>
                          </div>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>Upload Via Excel File</h5>
                            <h6>
                              Download Sample File:{" "}
                              <button
                                className="btn btn-secondary px-2"
                                onClick={handleSampleFile}
                              >
                                Download
                              </button>
                            </h6>
                          </div>
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Select File :
                          </label>
                          <div className="col-md-10">
                            <input
                              type="file"
                              className="form-control"
                              placeholder="Select Your File *"
                              required
                              onChange={handleUploadExcelFile}
                            />
                          </div>
                        </Row>
                        <Row className="mb-4">
                          <div className="col-10 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary px-5"
                              disabled={loading}
                            >
                              Upload
                            </button>
                          </div>
                        </Row>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCategoryPage;
