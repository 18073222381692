import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";
import axios from "axios";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = ({ ambulanceOrgFilterData, fetchData }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ambulanceCompanyId, setAmbulanceCompanyId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete user
  const handleDeleteCompany = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/ambulance/organization/delete/${ambulanceCompanyId}`
      );
      if (data?.status === 200) {
        fetchData();
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsDeleteModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setAmbulanceCompanyId(cell._id);
                setIsViewModalOpen(!isViewModalOpen);
              }}
            >
              <button
                className="btn btn-sm remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setAmbulanceCompanyId(cell._id);
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Remove
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = ambulanceOrgFilterData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      address: data.address,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      {/* // All table data start */}
      <DataTable columns={columns} data={data2} pagination />
      {/* // All table data end */}
      {isViewModalOpen && (
        <AmbulanceCompanyView
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          apiRoute={apiRoute}
          ambulanceCompanyId={ambulanceCompanyId}
        />
      )}
      {isDeleteModalOpen && (
        <AmbulanceCompanyDelete
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          handleDeleteCompany={handleDeleteCompany}
        />
      )}
    </>
  );
};

const AmbulanceCompanyView = ({
  isViewModalOpen,
  setIsViewModalOpen,
  apiRoute,
  ambulanceCompanyId,
}) => {
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    const fetchSignleOrg = async () => {
      try {
        const data = await axios.get(
          `${apiRoute}/api/ambulance/organization/single/${ambulanceCompanyId}`
        );
        if (data?.status === 200) {
          setOrgData(data.data);
        }
      } catch (err) {
        if (err) {
        }
      }
    };

    fetchSignleOrg();
  }, [apiRoute, ambulanceCompanyId]);

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Name : {orgData?.name}
            <br />
            Email : {orgData?.email}
            <br />
            Phone Number : {orgData?.phoneNumber}
            <br />
            Address : {orgData?.address}
          </label>
        </div>
      </div>
    </Modal>
  );
};

const AmbulanceCompanyDelete = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  handleDeleteCompany,
}) => {
  return (
    <Modal
      isOpen={isDeleteModalOpen}
      toggle={() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Are You Sure to Delete This Ambulance Company?
        </h5>
        <button
          type="button"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDeleteCompany}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export { BasicTable };
